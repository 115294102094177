<template>
<div class="doc-container">
    <CCard class="p-0">
        <CCardHeader>
            <div class="d-flex">
                <div class="flex-grow-1">
                    <h5 class="mb-0" style="line-height:2rem"><font-awesome-icon icon="folder" class="mr-10"/> Persetujuan</h5>
                </div>
                <div class="flex-grow-0 ml-10"></div>
            </div>
        </CCardHeader>
        <CCardBody class="doc-action-container">
            <CTabs>
                <!-- My Approval Submitted -->
                <CTab active>
                    <template slot="title">
                        <font-awesome-icon icon="paper-plane" class="mr-10"/> Pengajuan Saya
                    </template>
                    <div class="p-0">
                        <v-data-table
                            :loading="loading"
                            loading-text="Memuat Data..."
                            :headers="submittedTable.headers"
                            :options.sync="submittedOptionsTable"
                            :server-items-length="submittedTable.totalData"
                            :multi-sort="false"
                            :items="submittedTable.dataList"
                            :footer-props="submittedTable.footerProps"
                            class="elevation-1">
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2 text-primary" @click="gotoDetail(item)">
                                    mdi-eye
                                </v-icon>
                            </template>
                            <template v-slot:no-data>
                                <span>Belum melakukan pengajuan surat.</span>
                            </template>
                        </v-data-table>
                    </div>
                </CTab>
                <!-- Need Action -->
                <CTab>
                    <template slot="title">
                        <font-awesome-icon icon="check-circle" class="mr-10"/> Perlu Tindakan
                    </template>
                    <div class="p-0">
                        <v-data-table
                            :loading="loading"
                            loading-text="Memuat Data..."
                            :headers="incomingTable.headers"
                            :options.sync="incomingOptionsTable"
                            :server-items-length="incomingTable.totalData"
                            :multi-sort="false"
                            :items="incomingTable.dataList"
                            :footer-props="incomingTable.footerProps"
                            class="elevation-1">
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2 text-primary" @click="showIncomingModal(item)">
                                    mdi-share
                                </v-icon>
                            </template>
                            <template v-slot:no-data>
                                <span>Tidak ada surat pengajuan diterima.</span>
                            </template>
                        </v-data-table>
                    </div>
                    <CModal :show.sync="incomingActionModal.isShow">
                        <template #header>
                            <h5 class="modal-title">{{ incomingActionModal.modalTitle }}</h5>
                        </template>
                        <!-- Input Form -->
                        <div class="p-1">
                            <CInput
                                v-model="incomingActionModal.input.approvalId"
                                type="hidden"/>
                            <CInput
                                v-model="incomingActionModal.input.docId"
                                type="hidden"/>
                            <CInput
                                v-model="incomingActionModal.input.docNo"
                                label="Nomor Surat"
                                placeholder="Nomor Surat"
                                readonly/>
                            <CInput
                                v-model="incomingActionModal.input.title"
                                label="Perihal"
                                placeholder="Perihal"
                                readonly/>
                            <div class="mb-20">
                                <label class="typo__label">Tindakan</label>
                                <multiselect
                                    v-model="incomingActionModal.input.action"
                                    :options="['Setuju','Tolak']"
                                    :allow-empty="false"
                                    :close-on-select="true"
                                    placeholder="Pilih Tindakan">
                                </multiselect>
                            </div>
                            <div v-if="incomingActionModal.input.action == 'Tolak'">
                                <CTextarea
                                    v-model="incomingActionModal.input.message"
                                    label="Catatan"
                                    placeholder="Contoh: Surat Belum Sesuai."
                                    rows="3"/>
                            </div>
                        </div>
                        <template #footer>
                            <CButton @click="closeIncomingModal" class="btn-sm">Batal</CButton>
                            <CButton @click="approvalAction" color="success" class="btn-sm">
                                <font-awesome-icon v-if="incomingActionModal.submitBtn.loader" icon="spinner" spin/> {{ incomingActionModal.submitBtn.text }}
                            </CButton>
                        </template>
                    </CModal>
                </CTab>
            </CTabs>
        </CCardBody>
    </CCard>
</div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
	names: 'Approval',
	data () {
        const submittedTable = {
			headers: [
				{ text: 'Perihal', value: 'title', align: 'center', sortable: true },
				{ text: 'Nomor Surat', value: 'docNo', align: 'center', sortable: false },
                { text: 'Diajukan Pada', value: 'submittedDate', align: 'center', sortable: false },
                { text: 'Status', value: 'status', align: 'center', sortable: false },
				{ text: 'Aksi', value: 'actions', align: 'center', sortable: false }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': 'Data Ditampilkan'
			},
			totalData: 0,
			dataList: []
        }
        const submittedActionModal = {
            isShow: false,
			modalTitle: 'Disposisi Surat'
        }

        const incomingTable = {
			headers: [
				{ text: 'Perihal', value: 'title', align: 'center', sortable: true },
				{ text: 'Nomor Surat', value: 'docNo', align: 'center', sortable: false },
                { text: 'Diajukan Oleh', value: 'submittedBy', align: 'center', sortable: false },
                { text: 'Diajukan Pada', value: 'submittedDate', align: 'center', sortable: false },
				{ text: 'Aksi', value: 'actions', align: 'center', sortable: false }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': 'Data Ditampilkan'
			},
			totalData: 0,
			dataList: []
        }
        const incomingActionModal = {
            isShow: false,
            modalTitle: 'Pengajuan Surat',
            input: {
                approvalId: '',
                docId: '',
                docNo: '',
                title: '',
                message: '',
                action: 'Setuju'
            },
            default: {
                approvalId: '',
                docId: '',
                docNo: '',
                title: '',
                message: '',
                action: 'Setuju'
            },
            submitBtn: {
				loader: false,
                text: 'Simpan'
            }
        }

		return {
			loading: true,
            submittedTable,
			submittedOptionsTable: {},
            incomingTable,
            incomingOptionsTable: {},
            incomingActionModal
		}
    },
    watch: {
		submittedOptionsTable: {
			deep: true,
			handler () {
				this.getDataList('sender')
					.then( data => {
						this.submittedTable.dataList = data.items
						this.submittedTable.totalData = data.total
					})
			}
		},
		incomingOptionsTable: {
			deep: true,
			handler () {
				this.getDataList('receiver')
					.then( data => {
						this.incomingTable.dataList = data.items
						this.incomingTable.totalData = data.total
					})
			}
		}
    },
	mounted () {
        this.getDataList('sender')
            .then( data => {
                this.submittedTable.dataList = data.items
                this.submittedTable.totalData = data.total
            })
        this.getDataList('receiver')
            .then( data => {
                this.incomingTable.dataList = data.items
                this.incomingTable.totalData = data.total
            })
	},
	methods: {
        // APIs
        getDataList (approvalChoice) {
            this.loading = true
			return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = 
                    (approvalChoice == 'receiver') ? this.incomingOptionsTable : this.submittedOptionsTable
                
				this.$store.dispatch(
					{
                        type: 'approval/get',
                        filter: {
                            choice: approvalChoice
                        },
						pagination: {
							limit: itemsPerPage,
							offset: (page-1) * itemsPerPage
						}
					}
				).then( res => {
					let items = []
					let total = 0
					res.result.forEach((item) => {
                        let approvalStatus = item.document.approval_status
                        let status = (approvalStatus === true) ? 'Selesai' :
                            (approvalStatus === false) ? 'Ditolak' : 'Sedang Proses'
                        let rowData = {
                            approvalId: item.approval_id,
                            docId: item.document.doc_id,
                            docNo: item.document.doc_no,
                            title: item.document.title,
                            submittedBy: item.actor_user.fullname,
                            status,
                            submittedDate: this.$moment(item.created_at).format("Y-MM-DD, HH:mm")
                        }
						items.push(rowData)
					})
					total = res.total_count
					resolve({ items, total })
				}).catch( err => {
                    reject(err)
                })
			})
        },
        // Navigation & Actions
        showIncomingModal (item) {
            this.incomingActionModal.input.approvalId = item.approvalId
			this.incomingActionModal.input.docId = item.docId
			this.incomingActionModal.input.docNo = item.docNo
			this.incomingActionModal.input.title = item.title
			this.incomingActionModal.isShow = true
		},
		closeIncomingModal () {
            this.incomingActionModal.input = Object.assign({}, this.incomingActionModal.default)
			this.incomingActionModal.isShow = false
        },
        approvalAction () {
            this.incomingActionModal.submitBtn = {
                loader: true,
                text: 'Memproses...'
            }

            // Set action approval
            this.$store.dispatch({
				type: 'approval/setActionApproval',
				formInput: this.incomingActionModal.input
			}).then( res => {
                this.getDataList('receiver')
                    .then( data => {
                        this.incomingTable.dataList = data.items
                        this.incomingTable.totalData = data.total

                        this.$toasted.success('Pengajuan berhasil ditanggapi.').goAway(3000)
                        this.incomingActionModal.submitBtn = {
                            loader: false,
                            text: 'Simpan'
                        }
                        setTimeout( e => {
                            this.closeIncomingModal()
                        }, 1500)
                    })
			}).catch( err => {
                this.$toasted.error('Gagal menanggapi surat, silahkan coba kembali!').goAway(3000)
                this.incomingActionModal.submitBtn = {
                    loader: false,
                    text: 'Simpan'
                }
            })
        },
        gotoDetail (item) {
            let approvalId = item.approvalId
			this.$router.push('/doc_action/approval_detail/' + approvalId)
        }
    }
}
</script>

<style scoped></style>